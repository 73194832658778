import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
//import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase/auth';
import useStateEx from '../hooks/useStateEx/useStateEx';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  isKrispEnabled: boolean;
  setIsKrispEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isKrispInstalled: boolean;
  setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
  screenShareStream?: MediaStream;
  setScreenShareStream(stream: MediaStream | null): void;
  whoIs?: string;
  setWhoIs(name?: string): void;
  patientIs?: string;
  setPatientIs(name?: string): void;
  rmName?: string;
  setRmName(name?: string): void;
  confirmMessage: string | null;
  setConfirmMessage(message?: string | null): void;
  information: string | null;
  setInformation(information: string | null): void;
  isMaster: boolean;
  setIsMaster(value: boolean): void;
  isPatient: boolean;
  setIsPatient(value: boolean): void;
  isStandBy: boolean;
  setIsStandBy(value: boolean): void;
  getIsStandBy(): Promise<boolean>;
  masterIs: string;
  setMasterIs(name?: string): void;
  isEpilogue: boolean;
  setIsEpilogue: (value: boolean) => void;
  audioBlocking: boolean;
  setAudioBlocking: (value: boolean) => void;
  audioOutputBlocking: boolean;
  setAudioOutputBlocking: (value: boolean) => void;
  isWait: boolean;
  setIsWait(value: boolean): void;
}

export const StateContext = createContext<StateContextType>({} as StateContextType);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<object>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [isKrispEnabled, setIsKrispEnabled] = useState(false);
  const [isKrispInstalled, setIsKrispInstalled] = useState(false);
  const [screenShareStream, setScreenShareStream] = useState<MediaStream | null>(null);
  const [whoIs, setWhoIs] = useState<string | null>(null);
  const [patientIs, setPatientIs] = useState<string | null>(null);
  const [rmName, setRmName] = useState<string | null>(null);
  const [confirmMessage, setConfirmMessage] = useState<string | null>(null);
  const [information, setInformation] = useState<string | null>(null);
  const [isMaster, setIsMaster] = useState(false);
  const [isPatient, setIsPatient] = useState(false);
  const [isStandBy, _setIsStandBy, getIsStandBy] = useStateEx(true);
  const [masterIs, setMasterIs] = useState<string | null>(null);
  const [isEpilogue, setIsEpilogue] = useState<boolean>(false);
  const [audioBlocking, setAudioBlocking] = useState(false);
  const [audioOutputBlocking, setAudioOutputBlocking] = useState(false);
  const [isWait, setIsWait] = useState(false);

  const setIsStandBy = (value: boolean) => {
    _setIsStandBy(value);
  };

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    isKrispEnabled,
    setIsKrispEnabled,
    isKrispInstalled,
    setIsKrispInstalled,
    screenShareStream,
    setScreenShareStream,
    whoIs,
    setWhoIs,
    patientIs,
    setPatientIs,
    rmName,
    setRmName,
    confirmMessage,
    setConfirmMessage,
    information,
    setInformation,
    isMaster,
    setIsMaster,
    isPatient,
    setIsPatient,
    isStandBy,
    setIsStandBy,
    getIsStandBy,
    masterIs,
    setMasterIs,
    isEpilogue,
    setIsEpilogue,
    audioBlocking,
    setAudioBlocking,
    audioOutputBlocking,
    setAudioOutputBlocking,
    isWait,
    setIsWait,
  } as StateContextType;

  /*if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else*/ if (
    process.env.REACT_APP_SET_AUTH === 'passcode'
  ) {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (user_identity, room_name) => {
        //const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
        const endpoint = process.env.REACT_APP_API_ENDPOINT
          ? process.env.REACT_APP_API_ENDPOINT + '/twilio/token'
          : '/token'; // token取得向き先変更

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            user_identity,
            room_name,
            create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
          }),
        }).then(res => res.json());
      },
      updateRecordingRules: async (room_sid, rules) => {
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/recordingrules';

        return fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ room_sid, rules }),
          method: 'POST',
        })
          .then(async res => {
            const jsonResponse = await res.json();

            if (!res.ok) {
              const recordingError = new Error(
                jsonResponse.error?.message || 'There was an error updating recording rules'
              );
              recordingError.code = jsonResponse.error?.code;
              return Promise.reject(recordingError);
            }

            return jsonResponse;
          })
          .catch(err => setError(err));
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setRoomType(res.room_type);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
