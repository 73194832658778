import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useLocation, useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
  finishStep,
  anotherRoomNameStep,
}

interface Window {
  alreadyAutomated: boolean | undefined;
  location: Location;
  history: History;
  encodeURI: (value: string) => string;
  close: () => void;
}
declare let window: Window;

export default function PreJoinScreens(props: { onUpdateRoomAndUser?: (room: string, name: string) => void }) {
  // room_id:string, user_name:string
  const { user, isMaster, isWait, setIsMaster, setMasterIs, setIsPatient, setIsWait } = useAppState();
  const { getAudioAndVideoTracks, getLocalDataTrack } = useVideoContext();
  // 要求に合わせてこうしたのかもしれないけど、ホントは↓の行が正しい
  //const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const params: { [key: string]: string } = useParams();
  const location = useLocation();
  const [step, setStep] = useState(Steps.roomNameStep);
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [mediaError, setMediaError] = useState<Error>();
  //const [initialized, setInitialized] = useState(false);

  //const url = new URL(window.location.href);
  //const params = url.searchParams;

  const URLRoomName: string | undefined = params.URLRoomName;
  //const [roomId, setRoomId] = useState<string | undefined>(params.room_id);
  //const userName: string | undefined = params.user_name;
  //const staffId: string | undefined = params.staff_id;
  const redirectUrl: string | undefined = params.redirect_url;
  //各サービス用パラメタ
  const serviceAlias: string | undefined = params.service_alias;
  const authorizedKey: string | undefined = params.authorized_key;
  const appId: string | undefined = params.app_id;

  const _getOptions = () => {
    let roomId_ = '';
    let isMaster_ = false;
    let isPatient_ = false;
    let userName_ = '';
    let isWait_ = false;
    const elements = location.search ? location.search.split('?')[1].split('&') : '';
    for (const element of elements) {
      const keyAndValue = element.split('=');
      if (keyAndValue && keyAndValue[0] === 'user_type') {
        isMaster_ = keyAndValue[1] ? keyAndValue[1] === 'doctor' : false;
      }
      if (keyAndValue && keyAndValue[0] === 'user_type') {
        isPatient_ = keyAndValue[1] ? keyAndValue[1] === 'patient' : false;
      }
      if (keyAndValue && keyAndValue[0] === 'room_id') {
        roomId_ = keyAndValue[1] ? keyAndValue[1] : '';
      }
      if (keyAndValue && keyAndValue[0] === 'user_name') {
        userName_ = keyAndValue[1] ? keyAndValue[1] : '';
        userName_ = decodeURIComponent(userName_);
      }
      if (keyAndValue && keyAndValue[0] === 'is_wait') {
        isWait_ = keyAndValue[1] ? keyAndValue[1] === 'true' : false;
      }
    }
    return { isMaster: isMaster_, isPatient: isPatient_, roomId: roomId_, userName: userName_, isWait: isWait_ };
  };

  // staffIdとroomNameはチャット送信で利用する。
  useEffect(() => {
    const {
      isMaster: isMaster_,
      isPatient: isPatient_,
      roomId: roomId_,
      userName: userName_,
      isWait: isWait_,
    } = _getOptions();
    setIsMaster(isMaster_);
    if (isMaster_ && userName_) {
      setMasterIs(userName_);
    }
    setIsPatient(isPatient_);
    setIsWait(isWait_);
    //localStorage.setItem('staffId', staffId ?? '');
    //localStorage.setItem('roomName', roomId ?? '');
    if (roomId_) {
      setRoomName(roomId_);
      setRmName(roomId_);
      if (userName_) {
        setName(userName_);
        if (!window.alreadyAutomated) {
          window.alreadyAutomated = true;
          setStep(Steps.deviceSelectionStep);
        } else {
          setStep(Steps.anotherRoomNameStep);
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  // 各サービスとのやり取りに利用する。
  useEffect(() => {
    if (appId) {
      localStorage.setItem('appId', appId);
    }
    if (authorizedKey) {
      localStorage.setItem('authorizedKey', authorizedKey);
    }
    if (serviceAlias) {
      localStorage.setItem('serviceAlias', serviceAlias);
    }
  }, [appId, authorizedKey, serviceAlias]);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks()
        .catch(error => {
          console.debug('Error acquiring local media:');
          console.dir(error);
          setMediaError(error);
        })
        .then(() => {
          getLocalDataTrack(); // 新規
        });
    }
  }, [getAudioAndVideoTracks, getLocalDataTrack, step, mediaError]);

  // eslint-disable-next-line
  const { setWhoIs, whoIs, setRmName, rmName } = useAppState();
  /*useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setName(whoIs ?? userName ?? '');
      setRoomName(rmName ?? URLRoomName ?? roomId);
    }
  });*/
  useEffect(() => {
    if (props.onUpdateRoomAndUser) props.onUpdateRoomAndUser(roomName, name);
    const names = name.toLowerCase().split('@master');
    if (names && names.length === 2 && names[1] === '') {
      setWhoIs(name.split('@')[0]);
    } else {
      setWhoIs(name);
    }
    // eslint-disable-next-line
  }, [name, setWhoIs]);

  useEffect(() => {
    if (props.onUpdateRoomAndUser) props.onUpdateRoomAndUser(roomName, name);
    setRmName(roomName);
    // eslint-disable-next-line
  }, [roomName, setRmName]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRmName(roomName);

    if (isMaster) {
      setMasterIs(name);
    }

    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  const _setRoomName = (value: string) => {
    setRoomName(value);
  };

  if (step === Steps.finishStep) {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      setStep(Steps.roomNameStep);
    }
  }
  //const { roomId: roomId_ } = _getOptions();
  return step === Steps.anotherRoomNameStep ? (
    <div style={{ backgroundColor: '#222' }}></div>
  ) : (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName ?? ''}
          setName={setName}
          setRoomName={_setRoomName}
          handleSubmit={handleSubmit}
          isMaster={isMaster}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={isMaster ? name.split('@')[0] : name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          setStep={setStep}
          isMaster={isMaster}
          isWait={isWait}
        />
      )}
    </IntroContainer>
  );
}
