import React, { useEffect, useRef } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useStrokeState from '../../../hooks/useStrokeState/useStrokeState';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  setStep: (step: Steps) => void;
  isMaster: boolean;
  isWait: boolean;
}
export interface Status {
  [index: string]: boolean;
}
interface Window {
  statuses: Status;
}
declare let window: Window;

export default function DeviceSelectionScreen({
  name,
  roomName,
  setName,
  setRoomName,
  setStep,
  isMaster,
  isWait,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const refJoinButton = useRef(null);
  const { getToken, isFetching, setIsStandBy } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const { userNameToReconnect, roomNameToReconnect } = useStrokeState();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  // TODO: Laravelテスト環境でトークン発行をしている。今後は、NLP_APIに持ってくる。
  async function getTokenToPhp(user_identity: any, room_name: any) {
    //const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
    const endpoint = process.env.REACT_APP_API_ENDPOINT + '/twilio/token'; // token取得向き先変更
    if (endpoint == undefined) {
      throw 'REACT_APP_TOKEN_ENDPOINT not set on .env ';
    }

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        id: room_name,
        identity: user_identity,
      }),
    }).then(res => res.json());
  }

  // eslint-disable-next-line
  useEffect(() => {
    // eslint-disable-next-line
    if (!disableButtons) {
      // さらに100ms後実行
      setTimeout(() => {
        // eslint-disable-next-line
        (refJoinButton.current as any).click();
      }, 300);
    }
  }, [disableButtons]);

  useEffect(() => {
    if (isMaster && !isWait) {
      // 待機室スキップ用
      const statuses_ = {};
      window.statuses = statuses_;
    }
    // eslint-disable-next-line
  }, [isWait]);

  /*useEffect(() => {
    const handle = setInterval(() => {
      if (refJoinButton && refJoinButton.current) {
        if (!(refJoinButton.current as any).disabled) {
          clearInterval(handle);
          // さらに100ms後実行
          setTimeout(() => {
            (refJoinButton.current as any).click();
          }, 100);
        }
      }
    }, 100);
  }, []);*/

  const handleJoin = () => {
    // ドクター以外がjoinするときは、かならず待機状態にする
    // !isMaster && setIsStandBy(true); 一旦別フラグで待機有無を決める
    !isWait && setIsStandBy(false);
    console.debug(process.env.REACT_APP_ENV, 'production');
    const uName = (userNameToReconnect() ?? '') !== '' ? userNameToReconnect() : name;
    if (name != uName) setName(uName);
    const rName = (roomNameToReconnect() ?? '') !== '' ? roomNameToReconnect() : roomName;
    if (roomName != rName) setRoomName(rName);
    if (process.env.REACT_APP_ENV === 'production') {
      // PHP側で取得している。
      getTokenToPhp(uName, rName).then(result => {
        videoConnect(result.data.token.value);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(result.data.token.value);
      });
    } else {
      getToken(uName, rName).then(({ token }) => {
        videoConnect(token);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
      });
    }
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            入室中
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {/* Join {roomName} */}
        {/* 空白 → */}&#x3164;{/*ㅤ ← 空白 */}
      </Typography>
      <Button
        ref={refJoinButton}
        variant="contained"
        color="primary"
        data-cy-join-now
        onClick={handleJoin}
        disabled={disableButtons}
        id="button-join-now"
        style={{ display: 'none' }}
      >
        ▶︎ 参加
      </Button>
    </>
  );
  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {/* Join {roomName} */}
        {/* 空白 → */}&#x3164;{/*ㅤ ← 空白 */}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} isMaster={isMaster} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton
                id="DeviceSelectionAudioUpper"
                className={classes.mobileButton}
                disabled={disableButtons}
              />
              <ToggleVideoButton
                id="DeviceSelectionVideoUpper"
                className={classes.mobileButton}
                disabled={disableButtons}
              />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton
                  id="DeviceSelectionAudioLower"
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
                <ToggleVideoButton
                  id="DeviceSelectionVideoLower"
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => setStep(Steps.finishStep)}>
                ◁ 退出
              </Button>
              <Button
                ref={refJoinButton}
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
                id="button-join-now"
              >
                ▶︎ 参加
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
