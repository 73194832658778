import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Participant, /*TrackPublication,*/ LocalVideoTrack, RemoteVideoTrack, Track } from 'twilio-video';
//import RemoteAudio from '../RemoteAudio/RemoteAudio';
import CanvasContainer from '../CanvasTools/CanvasContainer';
import MirroringContainer from '../CanvasTools/MirroringContainer';
import UseResizeObserver from '../../hooks/useResizeObserver/useResizeObserver';
import { useStrokeState } from '../../hooks/useStrokeState/useStrokeState';

import usePublications from '../../hooks/usePublications/usePublications';
import { LocalTrackPublication, RemoteTrackPublication, AudioTrack as IAudioTrack } from 'twilio-video';
import useScreenShareToggle from '../VideoProvider/useScreenShareToggle/useScreenShareToggle';
import InfoDialog from '../InfoDialog/InfoDialog';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import AudioTrack from '../AudioTrack/AudioTrack';
//import StandBy from '../../assets/stand-by.png';

// 新規
//import CanvasDrawingContainer from '../CanvasDrawingContainer';
//import useCanvasDrawingToolsData from '../../hooks/useCanvasDrawingToolsData/useCanvasDrawingToolsData';
// import useLocalTracks from '../VideoProvider/useLocalTracks/useLocalTracks';
//import { makeColorAlphaInCanvas, sendMessage, serializeCanvasDrawingData, videoToCanvas } from '../../utility';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import {
  //ALLOW_LOCAL_CANVAS_STREAM,
  CANVAS_STYLES,
  //CHANGE_CANVAS_TRACK_BLACK_TO_ALPHA,
  ENABLE_CANVAS_TRACK,
  MAIN_PARTICIPANT_ALLOWED_TRACKS,
  MAIN_PARTICIPANT_MAX_TRACKS,
  MAIN_PARTICIPANT_UNIQUE_TRACKS,
  MAIN_PARTICIPANT_PRIORITIZED_TRACK,
  TRACKS,
  //USE_CANVAS_FOR_CANVAS_TRACK,
} from '../../constants';
//import { CanvasDrawingData, CanvasDrawingToolsData } from '../../types';
import VideoTrack from '../VideoTrack/VideoTrack';
import { useAppState } from '../../state';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import { Status } from '../Buttons/toggleStandByButton/toggleStandByButton';
import useStateEx from '../../hooks/useStateEx/useStateEx';

// 新規 START{{...props.style, cursor: 'crosshair' }}
const useStyles = makeStyles(() => {
  return {
    ...createStyles(CANVAS_STYLES),
    parent: {
      position: 'relative',
      width: '100%',
      padding: 0,
      boxSizing: 'content-box',
      '&:before': {
        content: '""',
        display: 'block',
        paddingTop: '75%',
      },
    },
    child: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      boxSizing: 'content-box',
      backgroundColor: 'white',
      padding: 0,
      margin: 0,
    },
  };
});
// 新規 END

type DetectedVideoTrackPublication = {
  dimensions: {
    height: number;
    width: number;
  };
  id: string;
  isEnabled: boolean;
  isMuted: boolean;
  isStarted: boolean;
  isStopped: boolean;
  kind: string;
  mediaStreamTrack: never;
  name: string;
  processedTrack?: never;
  processor?: never;
};

type LocalVideoTrackPublication = {
  isTrackEnabled: boolean;
  kind: string;
  priority: string;
  track: DetectedVideoTrackPublication;
  trackName: string;
  trackSid: string;
};

interface ParticipantStatus {
  [identity: string]: {
    isStandBy: boolean;
  };
}

interface Window {
  participantStatus: ParticipantStatus;
  addEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions | undefined
  ) => void;
  removeEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions | undefined
  ) => void;
  dispatchEvent: (event: Event) => boolean;
  statuses: Status;
}
declare let window: Window;

export default function MainParticipant() {
  const element = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // 新規 START
  const { mainParticipant, audioParticipant } = useMainParticipant();
  const publications = usePublications(mainParticipant);
  const css = useStyles();
  //const [canvasDrawingToolsData] = useCanvasDrawingToolsData();
  // 起動時にDataTrackが作成されている。後で必要の場合、publish機能を作成すること。
  // const { getLocalDataTrack, localTracks } = useLocalTracks();
  const { /*getLocalDataTrack,*/ localTracks, room } = useVideoContext();
  // eslint-disable-next-line
  const [isSharingScreen] = useScreenShareToggle(room, () => {});
  const { reject, execClear, supressClear, setSharingMaster } = useStrokeState();
  const {
    screenShareStream,
    information,
    confirmMessage,
    isMaster,
    isWait,
    masterIs,
    isEpilogue,
    audioBlocking,
    audioOutputBlocking,
  } = useAppState();

  let audioTrack: IAudioTrack | null = null;
  if (audioParticipant !== null) {
    const mp = mainParticipant as Participant;
    const ap = audioParticipant as Participant;
    //mp.audioTracks.clear();
    const keys = ap.audioTracks.keys();
    for (const key of keys) {
      const value = ap.audioTracks.get(key);
      if (value) {
        mp.audioTracks.set(key, value);
        audioTrack = value.track;
      }
    }
  }

  const [isAudioEnabled] = useLocalAudioToggle();
  const [mute, setMute] = useState(true);
  /*useEffect(() => {
    const element = document.getElementById('toggle-audio-on-menu');
    if (!isMaster && element) {
      if (audioBlocking !== null && audioBlocking !== undefined && audioOutputBlocking !== undefined) {
        setMute(audioBlocking);
      }
      if (audioBlocking) {
        if (isAudioEnabled) {
          element.click();
        }
      } else if (audioBlocking === false) {
        if (!isAudioEnabled) {
          element.click();
        }
      }
    }
    // eslint-disable-next-line
  }, [audioBlocking, audioOutputBlocking]);*/
  //if (mute) audioTrack = null;

  /*console.debug({
    getLocalDataTrack,
    localTracks,
    videoTracks,
  });*/
  // useVideoContext に問題があれば：const { localTracks } = useRoom()
  // getLocalDataTrackはPreJoinScreensで利用されている。実行されると取得もステート保存も行われる。
  /*const dataTrack: LocalDataTrack | undefined = localTracks.filter(t => t.name === 'data')[0] as
    | LocalDataTrack
    | undefined;*/
  // const dataTrack: LocalDataTrack|undefined = undefined;
  /**
   * onCanvasDrawingData CanvasDrawingContainerに連携
   */
  /*const onCanvasDrawingDataToSend = useCallback(
    (canvasDrawingData: CanvasDrawingData): void => {
      console.debug('onCanvasDrawingDataToSend', canvasDrawingData);
      if (!dataTrack) {
        console.warn('DataTrackがない', { localTracks });
        return;
      }
      // sendMessage(JSON.stringify(canvasDrawingData)); // 全体を送信
      const serializedData = serializeCanvasDrawingData(canvasDrawingData);
      if (!serializedData) {
        console.error('Bad data', canvasDrawingData);
        return;
      }
      sendMessage(serializedData, dataTrack); // データのみを送信
    },
    [dataTrack, localTracks]
  );*/
  // 新規 END

  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  /*const isShowOverlay = useMemo(() => {
    // @ts-ignore
    const displaySurface = screenShareStream?.getTracks()[0].getSettings()?.displaySurface;
    return displaySurface === 'monitor';
  }, [screenShareStream]);*/

  const {
    setOffsetX,
    setOffsetY,
    setScreenScale,
    setRealWidth,
    setRealHeight,
    setUpperBias,
    setBodyBound,
    addSwitchCanvasListener,
    removeSwitchCanvasListener,
    closed,
  } = useStrokeState();

  //const [videoPriority, setVideoPriority] = useState<Track.Priority | null>('high');
  //const [canvasTrack, setCanvasTrack] = useState<LocalVideoTrack | RemoteVideoTrack | undefined>(undefined);
  //useEffect(() => {
  const videoTracks = Array.from(mainParticipant.videoTracks.values() || [])
    .map(p => p.track)
    .filter(t => !!t) as (LocalVideoTrack | RemoteVideoTrack)[];

  const localParticipant = room?.localParticipant;
  const isSelectedParticipant = mainParticipant === selectedParticipant;
  const isScreenShareParticipant = mainParticipant === screenShareParticipant;
  const isLocalParticipant = mainParticipant === localParticipant;
  // 新規 START
  // const canvasTrack: LocalVideoTrack | undefined = localTracks.filter(t => t.name.startsWith(TRACKS.CANVAS.prefix))[0] as LocalVideoTrack | undefined;
  const canvasTrackWork = /*!ALLOW_LOCAL_CANVAS_STREAM && isLocalParticipant
        ? undefined
        :*/ videoTracks.filter(
    t => t.name.startsWith(TRACKS.CANVAS.prefix)
  )[0] as LocalVideoTrack | RemoteVideoTrack | undefined;
  console.debug({ canvasTrackWork, localTracks });
  if (canvasTrackWork) {
    const wrapper = document.getElementById('canvasWrapper');
    if (wrapper) {
      wrapper.setAttribute('title', isLocalParticipant ? 'local' : 'remote');
    }
  }
  //setCanvasTrack(canvasTrackWork);
  const canvasTrack = canvasTrackWork;
  // 新規 END

  let videoPriority: Track.Priority | null = null;
  if (isScreenShareParticipant) {
    videoPriority = 'high'; //setVideoPriority('high');
  } else if (isSelectedParticipant) {
    videoPriority = 'standard'; //setVideoPriority('standard');
  } else if (isLocalParticipant) {
    videoPriority = 'low'; //setVideoPriority('low');
  }
  //}, [localTracks, mainParticipant, room?.localParticipant, screenShareParticipant, selectedParticipant]);

  /*
  const videoPriority =
    (isSelectedParticipant || isScreenShareParticipant) &&
    isLocalParticipant
      ? 'high'
      : null;
  */
  // type Track.Priority = "high" | "low" | "standard"

  /*
  Memoized Canvas:
  function MainCanvasDrawingContainer({ canvasDrawingToolsData, onCanvasDrawingDataToSend }: { canvasDrawingToolsData: CanvasDrawingToolsData, onCanvasDrawingDataToSend: (canvasDrawingData: CanvasDrawingData) => void }) {
    return (
      <CanvasDrawingContainer
        canvasDrawingToolsData={canvasDrawingToolsData}
        onCanvasDrawingData={onCanvasDrawingDataToSend}
        options={{
          enableDrawing: true,
          enableRemoteActions: true,
          enableRemoteDrawing: true
        }}
      />
    )
  }
  const MemoizedMainCanvasDrawingContainer = React.memo(MainCanvasDrawingContainer)
  // <MemoizedMainCanvasDrawingContainer  canvasDrawingToolsData={canvasDrawingToolsData} onCanvasDrawingData={onCanvasDrawingData}>
  */

  /**
   * TEST用。
   * 問題なければ、useEffect + ref を導入。
   */
  /*const addCanvas = () => {
    const wrapper = document.getElementById('canvas-track-wrapper');
    if (!wrapper) {
      console.log('no wrapper');
      return;
    }

    const video = wrapper.querySelector('video');
    if (!video) {
      console.log('no video');
      return;
    }

    if (wrapper.querySelector('canvas')) {
      console.log('no canvas');
      return;
    }

    const { canvas } = videoToCanvas(video, {
      onFrame: (c, ctx) => {
        if (CHANGE_CANVAS_TRACK_BLACK_TO_ALPHA) {
          makeColorAlphaInCanvas(ctx, ({ r, g, b }) => r + g + b === 0);
        }
        return c;
      },
    });

    wrapper.appendChild(canvas);
    video.style.visibility = 'hidden';
  };
  if (USE_CANVAS_FOR_CANVAS_TRACK) {
    addCanvas();
  }*/

  const [elementWidth, setElementWidth] = useState<number | null>();
  const [elementHeight, setElementHeight] = useState<number | null>();
  const updateTrackInfo = useCallback(
    (elementWidth: number, elementHeight: number) => {
      //let rect = (element && element.current) ? element.current.getBoundingClientRect() : {left: 0, top: 0, right: 0, bottom: 0};
      const filteredPublications: (LocalTrackPublication | RemoteTrackPublication)[] = [];
      filteredPublications.push(...publications.filter(p => p.trackName.includes('screen')));
      if (filteredPublications) {
        const publication = (filteredPublications[0] as unknown) as LocalVideoTrackPublication;
        if (!publication?.track?.dimensions?.width) {
          return false;
        } else {
          const realWidth = publication.track.dimensions.width;
          setRealWidth(realWidth);
          const realHeight = publication.track.dimensions.height;
          setRealHeight(realHeight);
          // 画像の横/縦よりもエレメントの横/縦の方が大きければ横マージン発生
          const ratioElement = elementWidth / elementHeight;
          const ratioReal = realWidth / realHeight;
          const horizontalMargined = ratioReal < ratioElement;
          if (horizontalMargined) {
            // 横マージンの計算
            const ratioH = elementHeight / realHeight;
            const marginW = (elementWidth - realWidth * ratioH) / 2;
            setOffsetX(marginW);
            setOffsetY(0);
            setScreenScale(ratioH);
          } else {
            // 縦マージンの計算
            const ratioW = elementWidth / realWidth;
            const marginH = (elementHeight - realHeight * ratioW) / 2;
            setOffsetX(0);
            setOffsetY(marginH);
            setScreenScale(ratioW);
          }
          return true;
        }
      }
    },
    [publications, setOffsetX, setOffsetY, setRealHeight, setRealWidth, setScreenScale]
  );

  const getAlignment = () => {
    const innerFunc = (): boolean => {
      const hasScreenTrack = publications.some(p => p.trackName.includes('screen'));
      if (hasScreenTrack) {
        if (elementWidth && elementHeight) {
          const container = document.getElementById('CanvasContainer');
          if (container) {
            const rect = container.getBoundingClientRect();
            if (rect) {
              // スマホレイアウトだとヘッダー分ずれる
              if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
                setUpperBias(rect.top);
              } else {
                setUpperBias(0);
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
          if (updateTrackInfo(elementWidth, elementHeight)) {
            setWidth(elementWidth);
            setHeight(elementHeight);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
    if (innerFunc()) {
      //window.alert('速攻で取れました。');
    } else {
      // 値が取れるまで10秒間（/sec）実施
      let count = 0;
      const handle = setInterval(() => {
        if (innerFunc()) {
          clearTimeout(handle);
          //window.alert(`リピート${count + 1}回目で取れました。`);
        }
        if (count++ === 10) {
          clearTimeout(handle);
          //window.alert('リピート10回目でも取れませんでした。');
        }
        count++;
      }, 1000);
    }
  };

  //const [screenElement, setScreenElement] = useState<ResizeObserverEntry>();
  const handleResize = (entries: ResizeObserverEntry[]) => {
    const entry = entries[0];
    //setScreenElement(entry);
    const hasScreenTrack = publications.some(p => p.trackName.includes('screen'));
    if (entry?.contentRect?.width && hasScreenTrack) {
      setElementWidth(entry.contentRect.width);
      setElementHeight(entry.contentRect.height);
    }
    if (hasScreenTrack) {
      // Draggable向け外枠更新
      const bodies = document.getElementsByTagName('body');
      if (bodies && bodies.length === 1) {
        const rect = bodies[0].getBoundingClientRect();
        setBodyBound({ left: rect.left, top: rect.top, right: rect.right, bottom: rect.bottom });
      }
      getAlignment();
    }
  };
  UseResizeObserver([element], handleResize);
  //const [filteredPublications, setFilteredPublications] = useState<(LocalTrackPublication | RemoteTrackPublication)[]>([]);

  const [isShowCanvas, setShowCanvas] = useState<boolean | null>(null);
  const listener = (val: boolean) => {
    if (isShowCanvas !== val) {
      setShowCanvas(val);
    }
  };

  /*const [screenTrackAlived, setScreenTrackAlived] = useState(false);
  const judgeMode = () => {
    if (room) {
      const screenTrack = Array.from<Participant>(room.participants.values())
        // the screenshare participant could be the localParticipant
        .concat(room.localParticipant)
        .find((participant: Participant) =>
          Array.from<TrackPublication>(participant.tracks.values()).find(track => track.trackName.includes('screen'))
        );
      if (screenTrack && !screenTrackAlived) {
        setScreenTrackAlived(true);
      } else if (!screenTrack && screenTrackAlived) {
        setScreenTrackAlived(false);
      }
    }
  };

  useEffect(() => {
    if (!screenTrackAlived) {
      if (isSharingScreen) {
        execClear();
      } else {
        supressClear();
      }
    }
  }, [isSharingScreen, screenTrackAlived]);*/

  /*const clearStroke = () => {
    supressClear();
  };*/

  // 初期化
  // eslint-disable-next-line
  const [handle, setHandle] = useState<any>(null);
  const participants = useParticipants();
  useEffect(() => {
    const participantStatus_: ParticipantStatus = {};
    participants.forEach(participant => {
      participantStatus_[participant.identity] = { isStandBy: true };
    });
    console.log(participantStatus_);
    window.participantStatus = participantStatus_;
    addSwitchCanvasListener(listener);
    window.addEventListener('responseStatus', _responseStatus);

    const handle_ = setInterval(_timerProc, 3000);
    setHandle(handle_);
    return () => {
      removeSwitchCanvasListener(listener);
      window.removeEventListener('responseStatus', _responseStatus);
      handle_ && clearInterval(handle_);
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const _timerProc = () => {
    const element = document.getElementById('toggle-audio-on-menu');
    if (!isMaster && element) {
      if ((audioBlocking !== null && audioBlocking === true) || audioOutputBlocking !== true) {
        setMute(true);
      } else {
        setMute(false);
      }
      if (audioBlocking) {
        if (isAudioEnabled) {
          element.click();
        }
      } else if (audioBlocking === false) {
        if (!isAudioEnabled) {
          element.click();
          handle && clearInterval(handle);
        }
      }
    }
  };

  // eslint-disable-next-line
  const [updateView, setUpdateView, getUpdateView] = useStateEx(0);
  // eslint-disable-next-line
  const _responseStatus = (e: any) => {
    if (e && e.detail) {
      const name = e.detail.name;
      //const participantStatus_ = { ...participantStatus };
      window.participantStatus[name] = { isStandBy: e.detail.isStandBy };
      //setParticipantStatus(participantStatus_);

      /*if (name === mainParticipant.identity) {
        setIsStandByMaster(e.detail.isStandBy);
        setTimeout(() => {
          setShowPermission(false);
        }, 5000);
      }*/
      getUpdateView().then(value => {
        setUpdateView(value + 1);
      });
    }
  };

  // ブラウザーの共有停止ボタン監視
  useEffect(() => {
    if (screenShareStream) {
      setSharingMaster(isSharingScreen);
    } else {
      setSharingMaster(false);
      supressClear();
      setElementWidth(0);
      setElementHeight(0);
    }
    // eslint-disable-next-line
  }, [screenShareStream]);

  useEffect(() => {
    if (isShowCanvas) {
      getAlignment();
    }
    // eslint-disable-next-line
  }, [isShowCanvas]);

  useEffect(() => {
    //
    console.debug('information has been changed.');
  }, [information]);

  useEffect(() => {
    if (room && !isWait && isMaster) {
      // 待機室スキップ用
      console.log('check participants');
      const temps = Array.from<Participant>(room.participants.values());
      const names_: string[] = [];
      for (const temp of temps) {
        names_.push(temp.identity);
      }
      console.log(names_);
      const statuses_: Status = { ...window.statuses };
      for (const name_ of names_) {
        if (window.statuses[name_] === undefined) {
          statuses_[name_] = false;
        }
      }
      window.statuses = statuses_;
      console.log(window.statuses);
      console.log(window.statuses[mainParticipant.identity]);
    }

    // eslint-disable-next-line
  }, [room, participants]);

  const paneHeight = () => {
    const pane = document.getElementById('background-pane');
    if (pane) {
      return pane.clientHeight;
    } else {
      return 0;
    }
  };

  const marginTop = (() => {
    return `${paneHeight() / 2 - 104}px`;
  })();

  const baseScreenWidth = (() => {
    const baseScreen = document.getElementById('base-screeen');
    if (baseScreen) {
      return `${baseScreen.clientWidth}px`;
    } else {
      return '0px';
    }
  })();

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={mainParticipant}>
      <ConfirmDialog message={confirmMessage} reject={reject} confirmed={execClear} />
      <InfoDialog information={information} onClosed={closed} />
      {/*isShowOverlay ? (
        <div className={css.videoOverlay}>
          <span>
            画面全体またはブラウザ
            ウィンドウは共有しないでください。共有すると、その画面またはウィンドウが合わせ鏡のように無限に表示されてしまいます。代わりに、1
            つのタブのみ共有するか、別のウィンドウを共有してください。
          </span>
        </div>
      ) : (
        <></>
      )*/}
      <div id="videoWrapper" className={css.videoWrapper} ref={element}>
        <ParticipantTracks
          participant={mainParticipant}
          videoOnly={true}
          enableScreenShare={!(mainParticipant === room?.localParticipant)}
          videoPriority={videoPriority}
          isLocalParticipant={mainParticipant === room?.localParticipant}
          allowedTracks={MAIN_PARTICIPANT_ALLOWED_TRACKS}
          prioritizedTrack={MAIN_PARTICIPANT_PRIORITIZED_TRACK}
          maxTracks={MAIN_PARTICIPANT_MAX_TRACKS}
          uniqueTracks={MAIN_PARTICIPANT_UNIQUE_TRACKS}
        />
        {audioTrack !== null && !mute && <AudioTrack track={audioTrack} />}
        <div id="canvasWrapper" className={css.canvasWrapper}>
          {canvasTrack && ENABLE_CANVAS_TRACK && (
            <>
              <div id="canvas-track-wrapper" className={css.canvasTrackWrapper}>
                <VideoTrack track={canvasTrack} key="CanvasTrack" />
                {
                  // <canvas>がベストになれば、ここでテスト用から本番ようにする。
                }
              </div>
            </>
          )}
          {/*<CanvasDrawingContainer
            canvasDrawingToolsData={canvasDrawingToolsData}
            onCanvasDrawingData={onCanvasDrawingDataToSend}
            options={{
              enableDrawing: true,
              enableRemoteActions: true,
              enableRemoteDrawing: true,
            }}
          />*/}
          {/*{isSharingScreen && isShowCanvas ? ( isSharingScreenは共有親ポジションを示す */}
          {isShowCanvas ? (
            <>
              <MirroringContainer
                className={css.child}
                style={{ backgroundColor: 'transparent' }}
                width={width}
                height={height}
              />
              <CanvasContainer
                className={css.child}
                style={{ backgroundColor: 'transparent' }}
                width={width}
                height={height}
                lineWidth={5}
                clear={false}
                id={'CanvasContainer'}
              />
            </>
          ) : (
            <></>
          )}
          {isMaster &&
            isWait &&
            mainParticipant.identity !== masterIs &&
            (window.statuses[mainParticipant.identity] ?? true) == true && (
              <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                {/*<img src={StandBy} style={{ maxWidth: '100%', objectFit: 'contain' }} />*/}
                {/*<img
                src={StandBy}
                style={{
                  width: element.current ? (element.current as any).scrollWidth : '100%',
                  objectFit: 'cover',
                  overflow: 'hidden',
                }}
              />*/}
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#1c1c1c',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {/*<div style={{ margin: 'auto', textAlign: 'center', fontSize: '20pt' }}>ただ今待機状態です。</div>*/}
                </div>
              </div>
            )}
          {isEpilogue && mainParticipant.identity !== masterIs && (
            <Box
              style={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                bottom: '0px',
                width: baseScreenWidth,
                backgroundColor: 'rgb(40, 42, 43)',
                padding: 'auto',
              }}
              id="background-pane"
            >
              {marginTop !== '-104px' && (
                <div
                  style={{
                    display: 'table',
                    width: '500px',
                    height: '208px',
                    backgroundColor: '#fff',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '16pt',
                    padding: '24px',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: `${marginTop}`,
                    borderRadius: '8px',
                  }}
                >
                  <p style={{ fontSize: '18pt', fontWeight: '600', lineHeight: '1.8rem' }}>通話終了</p>
                  <p style={{ lineHeight: '0.8rem' }}>医師により通話が終了されました。</p>
                </div>
              )}
            </Box>
          )}
        </div>
        <Box sx={{ width: 0, height: 0, color: '#f00', fontSize: 0 }}>{updateView}</Box>
      </div>
    </MainParticipantInfo>
  );
}

/*
相手用のCanvas:
<CanvasDrawingContainer
  canvasDrawingToolsData={canvasDrawingToolsData}
  onCanvasDrawingData={onCanvasDrawingDataToSend}
  options={{
    enableDrawing: false,
    enableRemoteActions: false,
    enableRemoteDrawing: true
  }}
/>
*/
